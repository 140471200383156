const el = {
    // ADD TO EXCEL
    'toast_email_campaign_test_in_progress': 'Η αποστολή του δοκιμαστικού μηνύματος είναι σε εξέλιξη!',
    'email_campaign_is_being_prepared': 'Η Εκστρατεία Email προετοιμάζεται. Σε μερικά δευτερόλεπτα (μέχρι και μερικά λεπτά) η αποστολή της Εκστρατείας Email θα ξεκινήσει.',

    // DO NOT REMOVE - USED BY ETB - DEPRECATED!
    '1.0': '1.0',
    '1.1': '1.1',
    '1.2': '1.2',
    '1.3': '1.3',
    '1.4': '1.4',
    '1.5': '1.5',
    '1.6': '1.6',
    '1.7': '1.7',
    '1.8': '1.8',
    '1.9': '1.9',
    '2.0': '2',
    '2.1': '2.1',
    '2.2': '2.2',
    '2.3': '2.3',
    '2.4': '2.4',
    '2.5': '2.5',
    '2.6': '2.6',
    '2.7': '2.7',
    '2.8': '2.8',
    '2.9': '2.9',

    'Arial': 'Arial',
    'Arial Black': 'Arial Black',
    'Book Antiqua': 'Book Antiqua',
    'Charcoal': 'Charcoal',
    'Comfortaa': 'Comfortaa',
    'Comic Sans': 'Comic Sans',
    'Courier': 'Courier',
    'Courier New': 'Courier New',
    'Gadget': 'Gadget',
    'Geneva': 'Geneva',
    'Georgia': 'Georgia',
    'Helvetica': 'Helvetica',
    'Impact': 'Impact',
    'Lucida Console': 'Lucida Console',
    'Lucida Grande': 'Lucida Grande',
    'Lucida Sans Unicode': 'Lucida Sans Unicode',
    'Monaco': 'Monaco',
    'Open Sans': 'Open Sans',
    'Palatino': 'Palatino',
    'Palatino Linotype': 'Palatino Linotype',
    'Roboto': 'Roboto',
    'Roboto Condensed': 'Roboto Condensed',
    'Roboto Slab': 'Roboto Slab',
    'Source Sans Pro': 'Source Sans Pro',
    'Tahoma': 'Tahoma',
    'Times': 'Times',
    'Times New Roman': 'Times New Roman',
    'Trebuchet': 'Trebuchet',
    'Verdana': 'Verdana',
    'cursive': 'cursive',
    'monospace': 'monospace',
    'sans-serif': 'sans-serif',
    'serif': 'serif',

    // PASTE AFTER THIS POINT!
    '100': '100',
    '200': '200',
    '300': '300',
    '400': '400 (Κανονικό)',
    '500': '500',
    '600': '600',
    '700': '700 (Έντονο)',
    '800': '800',
    '900': '900',
    'Account': 'Λογαριασμός',
    'Account updated': 'Οι αλλαγές αποθηκεύτηκαν',
    'Accounts': 'Λογαριασμοί',
    'Activity': 'Δραστηριότητα',
    'Add criteria': 'Προσθήκη κριτηρίων',
    'Add Email Address': 'Προσθήκη Διεύθυνσης Ηλεκτρονικού Ταχυδρομείου',
    'Add item': 'Προσθήκη αντικειμένου',
    'Add Labels': 'Προσθήκη ετικετών',
    'Add Life Event': 'Προσθήκη Γεγονότος Ζωής',
    'Add option': 'Εισαγωγή Επιλογής',
    'Add sorter': 'Προσθήκη ταξινομητή',
    'Address': 'Διεύθυνση',
    'Alignment': 'Στοίχιση',
    'ALL': 'όλα',
    'All changes will be discarded.': 'Όλες οι αλλαγές θα απορριφθούν.',
    'All Properties': 'Όλες οι Ιδιότητες',
    'An unexpected error has occurred': 'Παρουσιάστηκε μη αναμενόμενο σφάλμα',
    'Analytics': 'Αναλυτικά',
    'AND': 'και',
    'and_all': 'όλα',
    'ANY': 'οποιοδήποτε',
    'April': 'Απρίλιος',
    'ASC': 'Αύξουσα',
    'Ascending': 'Αύξουσα',
    'audit_created': 'Δημιουργήθηκε στις {dateTime} από {actor}',
    'audit_edited': '@:audit_updated',
    'audit_updated': 'Επεξεργάστηκε στις {dateTime} από {actor}',
    'August': 'Αύγουστος',
    'Available Credits': 'Διαθέσιμες Μονάδες',
    'Available soon': 'Σύντομα διαθέσιμο',
    'Available Soon': 'Σύντομα Διαθέσιμο',
    'Back to Categories': 'Επιστροφή στις Κατηγορίες',
    'Back to Email Campaign': 'Επιστροφή στην Εκστρατεία Email',
    'Background': 'Φόντο',
    'Background Color': 'Χρώμα Φόντου',
    'Background Image': 'Εικόνα Φόντου',
    'Background Image Repeat': 'Επανάληψη Εικόνας Φόντου',
    'Background Image Size': 'Μέγεθος Εικόνας Φόντου',
    'Basic': 'Βασικά',
    'BETWEEN': 'ανάμεσα',
    'Bold': 'Έντονο',
    'Border': 'Σύνορο',
    'Border Radius': 'Καμπυλότητα Συνόρου',
    'Bottom': 'Κάτω',
    'Branding': 'Ταυτότητα',
    'Broadcaster': 'Μεταδότης',
    'Broadcaster created': 'Ο Μεταδότης δημιουργήθηκε',
    'Broadcaster deleted': 'Ο Μεταδότης διεγράφη',
    'Broadcaster updated': 'Ο Μεταδότης αποθηκεύτηκε',
    'Broadcaster\'s \'from\' email is not verified': 'Το Email δεν είναι επαληθευμένο',
    'Broadcaster\'s \'from\' email is not verified (2)': 'Το Email δεν είναι επαληθευμένο',
    'Broadcaster\'s \'from\' email must be a unique email': 'Το Email πρέπει να είναι μοναδικό',
    'Broadcaster\'s \'reply-to\' email is not verified': 'Το Email δεν είναι επαληθευμένο',
    'Broadcaster\'s \'reply-to\' email is not verified (2)': 'Το Email δεν είναι επαληθευμένο',
    'Broadcaster\'s SMS Name must be unique': 'Το όνομα SMS πρέπει να είναι μοναδικό',
    'Browse Campaigns': 'Περιήγηση στις Εκστρατείες',
    'Browse Email Templates': 'Περιήγηση στα Πρότυπα Email',
    'Browse Images': 'Περιήγηση στις Εικόνες',
    'Browse People': 'Περιήγηση στα Άτομα',
    'Builder': 'Χτίστης',
    'BUILDER': 'ΧΤΙΣΤΗΣ',
    'Button': 'Κουμπί',
    'Button Content': 'Περιεχόμενο Κουμπιού',
    'Button Spacing': 'Περιθώρια Κουμπιού',
    'Button Style': 'Στυλ Κουμπιού',
    'by': 'από',
    'Call to action': 'Ενέργεια',
    'Call to Action': 'Ενέργεια',
    'CALL_LOG': 'Καταγραφή Τηλεφωνικής Κλήσης',
    'Campaign': 'Εκστρατεία',
    'CAMPAIGN_WEB_LINK_CLICK': 'Κλικ Συνδέσμου Εκστατείας',
    'Campaigns': 'Εκστρατείες',
    'Center': 'Κέντρο',
    'Change View Size': 'Αλλαγή προβολής',
    'Changes discarded': 'Οι αλλαγές αναιρέθηκαν',
    'Choose a file or drag it here': 'Επιλέξτε μία εικόνα ή σύρετε τη εδώ',
    'Choose a title or create a new one.': 'Επιλέξτε ένα τίτλο ή δημιουργήστε έναν καινούργιο.',
    'Choose a view': 'Επιλέξτε προβολή',
    'City': 'Πόλη',
    'Cohabitation': 'Συγκατοίκηση',
    'Color': 'Χρώμα',
    'Color Primary': 'Πρωτεύων Χρώμα',
    'Color Secondary': 'Δευτερεύων Χρώμα',
    'Column': 'Στήλη',
    'Column #1': 'Στήλη #1',
    'Column #2': 'Στήλη #2',
    'Column #3': 'Στήλη #3',
    'Column #4': 'Στήλη #4',
    'Column Inner Spacing': 'Εσωτερικά Περιθώρια Στήλης',
    'Column Width': 'Μήκος Στήλης',
    'Coming Soon': 'Σύντομα κοντά σας',
    'common_action_add': 'Προσθήκη',
    'common_action_apply': 'Εφαρμογή',
    'common_action_cancel': 'Ακύρωση',
    'common_action_choose': 'Επιλογή',
    'common_action_close': 'Κλείσιμο',
    'common_action_continue': 'Συνέχιση',
    'common_action_create': 'Δημιουργία',
    'common_action_create_and_edit': 'Δημιουργία και Επεξεργασία',
    'common_action_create_and_view': 'Δημιουργία και Προβολή',
    'common_action_create_quick': 'Γρήγορη Δημιουργία',
    'common_action_customize': 'Παραμετροποίηση',
    'common_action_delete': 'Διαγραφή',
    'common_action_disable': 'Απενεργοποίηση',
    'common_action_discard': 'Επαναφορά',
    'common_action_display': 'Προβολή',
    'common_action_download': 'Λήψη',
    'common_action_edit': 'Επεξεργασία',
    'common_action_enable': 'Ενεργοποίηση',
    'common_action_export': 'Εξαγωγή',
    'common_action_filter': 'Φιλτράρισμα',
    'common_action_import': 'Εισαγωγή',
    'common_action_logout': 'Αποσύνδεση',
    'common_action_okay': 'Εντάξει',
    'common_action_preview': 'Προεπισκόπηση',
    'common_action_print': 'Εκτύπωση',
    'common_action_refresh': 'Ανανέωση',
    'common_action_remove': 'Αφαίρεση',
    'common_action_reset': 'Επαναφορά',
    'common_action_return': 'Επιστροφή',
    'common_action_save': 'Αποθήκευση',
    'common_action_save_as': 'Αποθήκευση ως',
    'common_action_search': 'Αναζήτηση',
    'common_action_submit': 'Υποβολή',
    'common_action_use': 'Χρήση',
    'common_pagination_next': 'Επόμενο',
    'common_pagination_page_size_label': '{object} ανά σελίδα',
    'common_pagination_previous': 'Προηγούμενο',
    'common_pagination_results_phrase': 'κανένα αποτέλεσμα | ένα αποτέλεσμα | {count} αποτελέσματα',
    'common_search_no_results': 'Δε βρέθηκαν αποτελέσματα',
    'common_search_no_results_subtitle_1': 'Η αναζήτησή σας δεν ταιριάζει με κανένα αποτέλεσμα.',
    'common_search_no_results_tip_1': 'Ελέγξτε την ορθογραφία των λέξεων-κλειδιών ή ξεκινήστε μια νέα αναζήτηση.',
    'common_search_no_results_tip_adv_1': 'Ελέγξτε την ορθογραφία των λέξεων-κλειδιών, τις λογικές συνθήκες, τους τελεστές ή ξεκινήστε μια νέα αναζήτηση.',
    'common_search_results': 'Αποτελέσματα Αναζήτησης',
    'common_service_status_beta': 'Beta',
    'common_service_status_deprecated': 'Deprecated',
    'common_state_empty': 'Δεν υπάρχει τίποτα εδώ',
    'common_state_empty_results': 'Δε βρέθηκαν αποτελέσματα',
    'common_state_error_sww': 'Κάτι πήγε στραβά',
    'common_state_loading': 'Φορτώνει',
    'common_state_not_found': 'Δε βρέθηκε',
    'common_status_completed': 'Ολοκληρώθηκε',
    'common_status_in_progress': 'Σε Εξέλιξη',
    'Communication': 'Επικοινωνία',
    'Communication Subscription': 'Συνδρομή Επικοινωνίας',
    'Communication Subscription Management': 'Διαχείριση Συνδρομών Επικοινωνίας',
    'Communication Subscription submitted': 'Η Συνδρομή Επικοινωνίας υποβλήθηκε',
    'Communication Subscriptions': 'Συνδρομές Επικοινωνίας',
    'Communication Subscriptions are supported only for Emails and Phone Numbers': 'Οι συνδρομές επικοινωνίας υποστηρίζονται για Email και Αριθμούς Τηλεφώνου',
    'COMMUNICATION_SUBSCRIPTION': 'Συνδρομή Επικοινωνίας',
    'Company, Organization, Institution, etc.': 'Εταιρεία, Οργανισμός, Ίδρυμα, κτλ.',
    'CONSENT': 'Συγκατάθεση',
    'Consent to Communicate': 'Συγκατάθεση για Επικοινωνία',
    'Consent to Communicate (Accept)': 'Συγκατάθεση για Επικοινωνία (Αποδοχή)',
    'Consent to Process Data - Explicit': 'Συγκατάθεση για Επεξεργασία Δεδομένων - Ρητή',
    'Consent to Process Data - Explicit (Accept)': 'Συγκατάθεση για Επεξεργασία Δεδομένων - Ρητή (Αποδοχή)',
    'Consent to Process Data - Implicit': 'Συγκατάθεση για Επεξεργασία Δεδομένων - Σιωπηρή',
    'Contact': 'Στοιχεία Επικοινωνίας',
    'CONTACT_DETAIL_INVALID': 'Το Στοχείο Επικοινωνίας δεν είναι ορθό',
    'CONTACT_DETAIL_INVALID_TYPE': 'Το Στοιχείο Επικοινωνίας δεν έχει κατάλληλο τύπο',
    'CONTACT_DETAIL_INVALIDATED_BY_INCIDENT': 'Το Στοιχείο Επικοινωνίας τέθηκε ως μη έγκυρο εξαιτίας κάποιου συμβάντος',
    'CONTACT_DETAIL_MISSING': 'Το Στοιχείο Επικοινωνίας λείπει',
    'CONTACT_DETAIL_NOT_VALIDATED': 'Ο έλεγχος ορθότητας δεν έχει ολοκληρωθεί',
    'CONTACT_DETAIL_NOT_VERIFIED': 'Ο έλεγχος εγκυρότητας δεν έχει ολοκληρωθεί',
    'CONTACT_DETAIL_VERIFICATION_FAILED': 'Το Στοιχείο Επικοινωνίας δεν είναι έκγυρο',
    'CONTAIN': 'περιέχει',
    'Content': 'Περιεχόμενο',
    'Content & Design': 'Περιεχόμενο & Σχεδίαση',
    'Content and design will be reset.': 'Το περιεχόμενο και η σχεδίαση θα μηδενιστούν.',
    'CONTRACT': 'Εκτέλεση Συμβολαίου',
    'Could not retrieve image': 'Η εικόνα δεν μπόρεσε να ανακτηθεί',
    'Country': 'Χώρα',
    'Create ': 'Δημιουργία ',
    'Create Broadcaster': 'Δημιουργία Μεταδότη',
    'Create Email Campaign': 'Δημιουργία Εκστρατείας Email',
    'Create Email Template': 'Δημιουργία Προτύπου Email',
    'Create Life Event': 'Δημιουργία Γεγονότος Ζωής',
    'Create Person': 'Δημιουργία Ατόμου',
    'Create Property': 'Δημιουργία Ιδιότητας',
    'Create SMS Campaign': 'Δημιουργία Εκστρατείας SMS',
    'create_entity': 'Δημιουργία {entity}',
    'Created By': 'Δημιουργήθηκε από',
    'Created Date': 'Ημερομηνία Δημιουργίας',
    'Created Date Range': 'Ημερομηνία Δημιουργίας - Εύρος',
    'Credits': 'Μονάδες',
    'Custom': 'Προσαρμοσμένο',
    'Custom Title': 'Προσαρμοσμένος Τίτλος',
    'Date': 'Ημερομηνία',
    'Date of Birth': 'Ημερομηνία Γέννησης',
    'date_hierarchical_input_notice_1': 'Δε χρειάζεται να υποδείξετε μήνα ή/και ημέρα - μόνο το έτος είναι υποχρεωτικό.',
    'DECEASED': 'Αποθανών/ούσα',
    'Deceased': 'Αποθανών/ούσα',
    'December': 'Δεκέμβριος',
    'DECLARED_BUT_NOT_SPECIFIED': 'Άνγωστο',
    'Default Language': 'Προκαθορισμένη Γλώσσα',
    'Default Workspace': 'Προκαθορισμένος Χώρος Εργασίας',
    'Delete Activity?': 'Διαγραφή Δραστηριότητας;',
    'Delete Broadcaster?': 'Διαγραφή Μεταδότη;',
    'Delete Email Template?': 'Διαγραφή Προτύπου Email;',
    'Delete Property': 'Διαγραφή Ιδιότητας',
    'Delete Property Group?': 'Διαγραφή Γκρουπ;',
    'Delete Sender Email Address?': 'Διαγραφή Ηλεκτρονικής Διεύθυνσης Αποστολέα;',
    'Delete Workspace': 'Διαγραφή Χώρου Εργασίας',
    'Delete Workspace?': 'Διαγραφή Χώρου Εργασίας;',
    'Deleting Email Template': 'Γίνεται διαγραφή του Προτύπου Email',
    'Demographics': 'Δημογραφικά',
    'DESC': 'Φθίνουσα',
    'Descending': 'Φθίνουσα',
    'Description': 'Περιγραφή',
    'Details': 'Λεπτομέρειες',
    'df_edit_group_create_new': 'Δημιουργία νέου Γκρουπ',
    'df_edit_group_select_existing': 'Επιλογή από τα υπάρχοντα Γκρουπ',
    'df_edit_pg_pd_listing_no_properties': 'Δεν υπάρχουν Ιδιότητες σε αυτό το Γκρουπ',
    'df_properties_with_count': 'καμία Ιδιότητα | 1 Ιδιότητα | {count} Ιδιότητες',
    'Direct URL': 'Απευθείας Σύνδεσμος',
    'Discard changes': 'Απόρριψη αλλαγών',
    'Discard changes?': 'Επαναφορά αλλαγών;',
    'Do not communicate': 'Μην επικοινωνήσετε',
    'Do you need more information?': 'Χρειάζεστε περισσότερες πληροφορίες;',
    'DO_NOT_COMMUNICATE_FLAG': 'Υπάρχει ετικέτα Μην επικοινωνήσετε',
    'Domain Name': 'Όνομα Τομέα Χώρου (Domain)',
    'Edit ': 'Επεξεργασία ',
    'Edit Broadcaster': 'Επεξεργασία Μεταδότη',
    'Edit Email Campaign': 'Επεξεργασία Εκστρατείας Email',
    'Edit Email Template': 'Επεξεργασία Προτύπου Email',
    'Edit Person': 'Επεξεργασία Ατόμου',
    'Edit SMS Campaign': 'Επεξεργασία Εκστρατείας SMS',
    'Edited on': 'Επεξεργάστηκε στις',
    'Education': 'Εκπαίδευση',
    'Educational Organization': 'Εκπαιδευτικός Οργανισμός',
    'EL': 'Greek (Ελληνικά)',
    'el_val_form_field_df_pg_pd_name': 'Το όνομα δεν είναι έγκυρο. Παραδείγματα έγκυρων ονομάτων: ""firstName"", ""firstName1"", ""first_name_1""',
    'el_val_form_field_df_pg_pd_name_string_must_be_unique_in_list': 'Το όνομα αυτό υπάρχει ήδη',
    'el_val_form_field_email': 'Αυτό το πεδίο πρέπει να είναι μία έκγυρη διεύθυνση email',
    'el_val_form_field_length_max': 'Αυτό το πεδίο πρέπει να έχει μέχρι {max} χαρακτήρες',
    'el_val_form_field_length_min': 'Αυτό το πεδίο πρέπει να έχει τουλάχιστον {min} χαρακτήρες',
    'el_val_form_field_length_min_max': 'Αυτό το πεδίο μπορεί να έχει από {min} έως και {max} χαρακτήρες',
    'el_val_form_field_must_be_string': 'Αυτό το πεδίο πρέπει να είναι γραμματοσειρά',
    'el_val_form_field_not_blank': 'Αυτό το πεδίο είναι απαραίτητο',
    'el_val_form_field_required': 'Αυτό το πεδίο είναι απαραίτητο',
    'el_val_form_field_upper_ascii': 'Αυτό το πεδίο πρέπει να περιέχει μόνο κεφαλαίους λατινικούς χαρακτήρες',
    'Email': 'Email',
    'Email Address': 'Διεύθυνση Ηλεκτρονικού Ταχυδρομείου',
    'Email Addresses': 'Διευθύνσεις Email',
    'Email Campaign': 'Εκστρατεία Email',
    'Email Campaign Content & Design updated': 'Το περιεχόμενο και η σχεδίαση αποθηκεύτηκαν',
    'Email Campaign Content and Design': 'Περιεχόμενο & Σχεδίαση Εκστρατείας Email',
    'Email Campaign created': 'Η Εκστρατεία Email δημιουργήθηκε',
    'Email Campaign deleted': 'Η Εκστρατεία Email διεγράφη',
    'Email Campaigns': 'Εκστρατείες Email',
    'Email Template': 'Πρότυπο Email',
    'Email Template created': 'Το Πρότυπο Email δημιουργήθηκε',
    'Email Template deleted': 'Το Πρότυπο Email διεγράφη',
    'Email Template HTML Editor': 'Επεξεργαστής HTML Προτύπου Email',
    'Email Template updated': 'Το Πρότυπο Email αποθηκεύτηκε',
    'EMAIL_CAMPAIGN_BOUNCE': 'Περιστατικό Bounce Εκστατείας Email',
    'EMAIL_CAMPAIGN_CLICK': 'Κλικ Εκστατείας Email',
    'EMAIL_CAMPAIGN_COMPLAINT': 'Περιστατικό Complaint Εκστατείας Email',
    'email_campaign_content_desktop_edit_1': 'Μπορείτε να επεξεργαστείτε το περιεχόμενο και τη σχεδίαση από τον υπολογιστή σας.',
    'email_campaign_creator_notice_1': 'Αυτή η ενέργεια θα δημιουργήσει μία πρόχειρη Εκστρατεία Email.',
    'email_campaign_creator_notice_2': 'Κανένα μήνυμα δε θα αποσταλεί.',
    'email_campaign_creator_notice_3': 'Το όνομα και η περιγραφή είναι περιγραφικά πεδία και δε μπορούν να τροποποιηθούν.',
    'EMAIL_CAMPAIGN_DELIVERY': 'Παράδοση Εκστατείας Email',
    'email_campaign_desktop_edit_1': 'Μπορείτε να επεξεργαστείτε την Εκστρατεία Email από τον υπολογιστή σας.',
    'EMAIL_CAMPAIGN_OPEN': 'Άνοιγμα Εκστατείας Email',
    'EMAIL_CAMPAIGN_REJECT': 'Απόρριψη Εκστρατείας Email',
    'EMAIL_CAMPAIGN_RENDERING_FAILURE': 'Αποτυχία Μετατροπής Εκστατείας Email',
    'EMAIL_CAMPAIGN_SEND': 'Αποστολή Εκστρατείας Email',
    'EMAIL_LOG': 'Καταγραφή Μηνύματος Ηλ. Ταχυδρομείου',
    'email_template_desktop_edit_1': 'Μπορείτε να επεξεργαστείτε το Πρότυπο Email από τον υπολογιστή σας.',
    'EMPTY': 'είναι άδειο',
    'EN': 'English',
    'END_WITH': 'τελειώνει με',
    'Engagement': 'Αρραβώνας',
    'Enter internal value': 'Εισάγετε εσωτερική τιμή',
    'Enter label': 'Εισάγετε όνομα',
    'entity Activity': 'Δραστηριότητα | Δραστηριότητες',
    'entity Branding': 'Ταυτότητα | Ταυτότητες',
    'entity Broadcaster': 'Μεταδότης | Μεταδότες',
    'entity Campaign': 'Εκστρατεία | Εκστρατείες',
    'entity Data Privacy and Consent': 'Ιδιωτικότητα Δεδομένων και Συγκατάθεση | Ιδιωτικότητα Δεδομένων και Συγκατάθεση',
    'entity Email Campaign': 'Εκστρατεία Email | Εκστρατείες Email',
    'entity Email Template': 'Πρότυπο Email | Πρότυπα Email',
    'entity Person': 'Άτομο | Άτομα',
    'entity Sender Email Address': 'Διεύθυνση Email Αποστολέα | Διευθύνσεις Email Αποστολέων',
    'entity SMS Campaign': 'Εκστρατεία SMS | Εκστρατείες SMS',
    'entity Workspace': 'Χώρος Εργασίας | Χώροι Εργασίας',
    'entity Workspace Type': 'Τύπος Χώρου Εργασίας | Τύποι Χώρου Εργασίας',
    'EQUAL': 'είναι',
    'Example': 'Παράδειγμα',
    'Explanation': 'Επεξήγηση',
    'Extra Bold': 'Πολύ Έντονο',
    'Family': 'Οικογένεια',
    'February': 'Φεβρουάριος',
    'File Import': '@:FILE_IMPORT',
    'FILE_IMPORT': 'Εισαγωγή Αρχείου',
    'Filters': 'Φίλτρα',
    'First Color Accent': 'Χρώμα Έμφασης 1',
    'First Met': 'Γνωριμία',
    'First Name': 'Όνομα',
    'Font': 'Γραμματοσειρά',
    'Font Weight': 'Βάρος Κειμένου',
    'Footer': 'Υποσέλιδο',
    'From Email': 'Από - Email',
    'From Name': 'Από - Όνομα',
    'General': 'Γενικά',
    'Getting Started': 'Ξεκινώντας',
    'Go to start page': 'Πηγαίνετε στην αρχική',
    'Graduated': 'Αποφοίτηση',
    'GREATER_THAN': 'μεγάλυτερο από',
    'GREATER_THAN_EQUAL': 'ίσο ή μεγάλυτερο από',
    'Group': 'Γκρουπ',
    'Group Internal Name': 'Εσωτερικό Όνομα Γκρουπ',
    'Group Label': 'Όνομα Γκρουπ',
    'Groups': 'Γκρουπ',
    'Header': 'Κεφαλίδα',
    'Height': 'Ύψος',
    'Home': 'Αρχική',
    'Home & Living': 'Σπίτι και Ζωή',
    'HTML': 'HTML',
    'HTML Editor': 'Επεξεργαστής HTML',
    'ID': 'Αναγνωριστικό',
    'Image': 'Εικόνα',
    'Image Link': 'Σύνδεσμος Εικόνας',
    'Import design by Email Template': 'Εισαγωγή σχεδίασης από ένα Προτύπου Email',
    'IN': 'μέσα',
    'INCOMPLETE_INFORMATION': 'Ελλιπείς πληροφορίες',
    'INDIVIDUAL': 'Φυσικό Πρόσωπο',
    'Inner Spacing': 'Εσωτερικά Περιθώρια',
    'Interests & Activities': 'Ενδιαφέροντα και Δραστηριότητες',
    'Internal Name': 'Εσωτερικό Όνομα',
    'Internal Value': 'Εσωτερική Τιμή',
    'Invalid': 'Μη έγκυρο',
    'Item': 'Αντικείμενο | Αντικείμενα',
    'Item #': 'Αντικείμενο #',
    'January': 'Ιανουάριος',
    'July': 'Ιούλιος',
    'June': 'Ιούνιος',
    'Justify': 'Πλήρης',
    'Label': 'Όνομα',
    'Language': 'Γλώσσα',
    'Last Modified By': 'Τελευταίτα Επεξεργασία από',
    'Last Modified Date': 'Ημερομηνία Τελευταίας Επεξεργασίας',
    'Last Modified Date Range': 'Ημερομηνία Τελευταίας Επεξεργασίας - Εύρος',
    'Last Name': 'Επώνυμο',
    'Lawful Basis': 'Νομική Βάση',
    'Left': 'Αριστερά',
    'Left & Right': 'Αριστερά & Δεξιά',
    'Left Job': 'Παραίτηση από τη δουλειά',
    'Left Right': 'Αριστερά Δεξιά',
    'Left School': 'Τέλειωσε το σχολείο',
    'legacyGpUiUserRecord_person:person_left_sidebar_summary_card': 'Παραμετροποίηση αριστερής στήλης',
    'Legal': 'Νομικά',
    'Legitimate Interest': 'Έννομο Συμφέρον',
    'LEGITIMATE_INTERESTS': 'Έννομο Συμφέρον',
    'LESS_THAN': 'μικρότερο από',
    'LESS_THAN_EQUAL': 'ίσο ή μικρότερο από',
    'Life Events': 'Γεγονότα Ζωής',
    'Light': 'Λεπτό',
    'Limit': 'Όριο',
    'Line Height': 'Ύψος Γραμμής',
    'Link': 'Σύνδεσμος',
    'Links': 'Σύνδεσμοι',
    'Location': 'Τοποθεσία',
    'Logo': 'Λογότυπο',
    'Logo (Favicon)': 'Λογότυπο (τύπου Favicon)',
    'Logo (Icon)': 'Λογότυπο (Εικονίδιο)',
    'Logo (Square)': 'Λογότυπο (Τετράγωνο)',
    'Loss of a Loved One': 'Απώλεια αγαπημένου προσώπου',
    'Manage Broadcasters': 'Διαχείριση Μεταδοτών',
    'March': 'Μάρτιος',
    'MARKETING_CALL': 'Προωθητικές Κλήσεις',
    'MARKETING_EMAIL': 'Προωθητικά Email',
    'MARKETING_SMS': 'Προωθητικά SMS',
    'Marriage': 'Γάμος',
    'Matches': 'Ταιριάζει με',
    'Max Communication Subscriptions per Import': 'Μέγιστος αριθμός Συνδρομών Επικοινωνίας ανά εισαγωγή',
    'Max People per Export': 'Μέγιστος αριθμός Ατόμων ανά εξαγωγή',
    'Max People per Import': 'Μέγιστος αριθμός Ατόμων ανά εισαγωγή',
    'Max Recipients per Email Campaign': 'Μέγιστος αριθμός παραληπτών εκστρατείας email',
    'Max Recipients per SMS Campaign': 'Μέγιστος αριθμός παραληπτών εκστρατείας SMS',
    'May': 'Μάιος',
    'Menu': 'Μενού',
    'Metadata': 'Μεταδεδομένα',
    'Milestones & Achievements': 'Ορόσημα και Επιτεύγματα',
    'Moved': 'Μετακόμιση',
    'Name': 'Όνομα',
    'Naming': 'Ονόματα',
    'Naming VOC': 'Ονόματα (Κλητική)',
    'Navigation Bar': 'Μπάρα Περιήγησης',
    'Navigation Bar with Logo': 'Μπάρα Περιήγησης με Λογότυπο',
    'New Call Log': 'Νέα Καταγραφή Κλήσης',
    'New Child': 'Νέο παιδί',
    'New Communication Subscription': 'Νέα Συνδρομή Επικοινωνίας',
    'New Email Log': 'Νέα Καταγραφή Email',
    'New Group': 'Νέο Γκρουπ',
    'New Job': 'Νέα δουλειά',
    'New Message': 'Νέο Μήνυμα',
    'New Note': 'Νέα Σημείωση',
    'New Pet': 'Νέο κατοικίδιο',
    'New Property': 'Νέα Ιδιότητα',
    'New Relationship': 'Νέα σχέση',
    'New Roommate': 'Νέος συγκάτοικος',
    'New School': 'Νέο σχολείο',
    'New Task': 'Νέα Εργασία',
    'New Vehicle': 'Νέο Όχημα',
    'No': 'Όχι',
    'no': 'όχι',
    'No data': 'Χωρίς δεδομένα',
    'No matching data': 'Δεν υπάρχουν δεδομένα που να ταιριάζουν',
    'No Repeat': 'Χωρίς Επανάληψη',
    'no_sender_email_addresses_1': 'Δεν υπάρχουν επαληθευμένες διευθύνσεις email',
    'Normal': 'Κανονικό',
    'Not Found': 'Δε Βρέθηκε',
    'NOT_ALL': 'όχι όλα',
    'NOT_APPLICABLE': 'Δεν Εφαρμόζεται',
    'NOT_BETWEEN': 'όχι ανάμεσα',
    'NOT_CONTAIN': 'δεν περιέχει',
    'NOT_EMPTY': 'δεν είναι άδειο',
    'NOT_END_WITH': 'δεν τελειώνει με',
    'NOT_EQUAL': 'δεν είναι',
    'NOT_IN': 'όχι μέσα',
    'NOT_OPTED_IN_OR_OUT': 'Δεν έχει Εγγραφεί',
    'NOT_SPECIFIED': 'Άνγωστο',
    'NOT_START_WITH': 'δεν αρχίζει με',
    'NOTE': 'Σημείωση',
    'November': 'Νοέμβριος',
    'Occupation': 'Απασχόληση',
    'October': 'Οκτώβριος',
    'of_the_following_and': 'τα παρακάτω',
    'of_the_following_or': 'από τα παρακάτω',
    'Offline Sources': '@:OFFLINE_SOURCES',
    'OFFLINE_SOURCES': 'Offline Πηγές',
    'ONE_TO_ONE_CALL': 'Προσωπικές Κλήσεις',
    'ONE_TO_ONE_EMAIL': 'Προσωπικά Email',
    'ONE_TO_ONE_SMS': 'Προσωπικά SMS',
    'Online Sources': '@:ONLINE_SOURCES',
    'ONLINE_SOURCES': 'Online Πηγές',
    'OPTED_IN': 'Έχει Εγγραφεί',
    'OPTED_OUT': 'Έχει Απεγγραφεί',
    'Options': 'Επιλογές',
    'OR': 'ή',
    'or_any': 'οποιοδήποτε',
    'ORGANIZATION': 'Οργανισμός',
    'Origin': 'Προέλευση',
    'OTHER': 'Άλλο',
    'Outer Spacing': 'Εξωτερικά Περιθώρια',
    'Parenthood': 'Γονεϊκότητα',
    'pd_edit_label_Personalization': 'Ιδιότητα Εξατομίκευσης',
    'pd_edit_label_Queryable': 'Αναζητήσιμη Ιδιότητα',
    'pd_edit_title_Advanced': 'Εξιδεικευμένες Ρυθμίσεις',
    'pd_edit_title_Basics': 'Βασικά',
    'People': 'Άτομα',
    'People Explorer': 'Εξερευνητής',
    'People per page': 'Άτομα ανά σελίδα',
    'people_first_use_btn_prompt_1': 'Προσθέστε νέο Άτομο',
    'people_first_use_prompt_mc_1': 'Ξεκινήστε με το Cloutlayer προσθέτωντας τις επαφές σας!',
    'people_first_use_subtitle_1': 'Φαίνεται ότι δεν έχετε προσθέσει κανένα άτομο ακόμα.',
    'Person': 'Άτομο',
    'Person created': 'Το Άτομο δημιουργήθηκε',
    'Person deleted': 'Το Άτομο διεγράφη',
    'Person updated': 'Το Άτομο αποθηκεύτηκε',
    'person_activity_action_call_log': 'Καταγραφή Κλήσης',
    'person_activity_action_com_sub': 'Συνδρομή Επικοινωνίας',
    'person_activity_action_email_log': 'Καταγραφή Email',
    'person_activity_action_note': 'Σημείωση',
    'person_activity_action_task': 'Εργασία',
    'person_com_sub_no_identity_profiles': 'Αυτό το Άτομο δεν έχει στοιχεία επικοινωνίας ή κοινωνικά δίκτυα',
    'PERSON_CREATE': 'Δημιουργία Ατόμου',
    'person_create_edit_gdpr_statement_checkbox': 'Έχω έγκυρη νομική βάση για την επεξεργασία των δεδομένων του Ατόμου.',
    'person_creator_gdpr_notice': 'Έχω τουλάχιστον μία νομική βάση ώστε να επεξεργαστώ τα δεδομένα του ατόμου.',
    'person_creator_gdpr_notice_lawful_basis': 'Έννομο συμφέρον, Εκτέλεση σύμβασης, Ελεύθερη συναίνεση από το άτομο, Συγκατάθεση από το άτομο ή Δεν απαιτείται τίποτα από τα προηγούμενα.',
    'person_creator_validation_at_least_one_identifying': 'Ένα Άτομο πρέπει να έχει τουλάχιστον ένα αναγνωριστικό πεδίο',
    'PERSON_DELETE': 'Διαγραφή Ατόμου',
    'PERSON_UPDATE': 'Επεξεργασία Ατόμου',
    'Phone Number': 'Αριθμός Τηλεφώνου',
    'Please contact support': 'Παρακαλούμε όπως επικοινωνήσετε με την υποστήριξη',
    'Please enter a keyword': 'Παρακαλούμε εισάγετε μία λέξη κλειδί',
    'Please enter Workspace name': 'Παρακαλούμε εισάγετε το όνομα',
    'Portal': '@:PORTAL',
    'PORTAL': 'Πλατφόρμα',
    'Priority': 'Προτεραιότητα',
    'Privacy Policy': 'Πολιτική Απορρήτου',
    'Privacy Policy URL': 'Πολιτική Απορρήτου (Σύνδεσμος URL)',
    'pro_def_edit_modal_create_delete_notice_1': 'Η ενέργεια αυτή είναι μη αναστρέψιμη',
    'pro_def_edit_modal_create_delete_notice_2': 'Ίσως χρειαστούν μερικά λεπτά μέχρι να ολοκληρωθεί αυτή η διαδικασία',
    'pro_def_edit_modal_create_notice_1': 'Μετά τη δημιουργία η Ιδιότητα δε μπορεί να τροποποιηθεί',
    'pro_def_edit_modal_delete_notice_1': 'Τα δεδομένα θα διαγραφούν οριστικά',
    'Profile': 'Προφίλ',
    'Promotion': 'Προώθηση',
    'prop_def_edit_enumeration_notice_1': 'Η πρώτη επιλογή χρησιμοποιείται αυτόματα ως η προκαθορισμένη επιλογή στις φόρμες.',
    'prop_def_edit_enumeration_notice_2': 'Μόνο ένα όνομα επιλογής μπορεί να είναι κενό προκειμένου να δηλώσει άδεια τιμή στις φόρμες. Ωστόσο, η εσωτερική τιμή της επιλογής είναι υποχρεωτική.',
    'prop_def_edit_form_enumValues_labels_unique': 'Τα ονόματα {valuesListLiteral} πρέπει να είναι μοναδικά',
    'prop_def_edit_form_enumValues_max_length': 'Το όνομα και η εσωτερική τιμή των επιλογών πρέπει να έχει μέχρι {max} χαρακτήρες',
    'prop_def_edit_form_enumValues_value_invalid_regex': 'Οι επιλογές περιέχουν μη έγκυρες εσωτερικές τιμές {valuesListLiteral}. Παραδείγματα έγκυρων εσωτερικών τιμών ""BASIC"", ""SOCIAL_MEDIA"", ""SOCIAL_MEDIA_1"", ""SOCIALMEDIA1"".',
    'prop_def_edit_form_enumValues_values_unique': 'Οι εσωτερικές τιμές {valuesListLiteral} πρέπει να είναι μοναδικές',
    'Property Definition created': 'Η Ιδιότητα δημιουργήθηκε',
    'Property Definition deleted': 'Η Ιδιότητα διεγράφη',
    'Property Definition updated': 'Η Ιδιότητα αποθηκεύτηκε',
    'Property Group created': 'Το Γκρουπ δημιουργήθηκε',
    'Property Group deleted': 'Το Γκρουπ διεγράφη',
    'PROPERTY_CHANGE_EMAIL': 'Αλλαγή Email',
    'PROPERTY_CHANGE_MOBILIZER': 'Αλλαγή Κινητοποιητή',
    'PROPERTY_CHANGE_PHONE_NUMBER': 'Αλλαγή Αριθμού Τηλεφώνου',
    'PROPERTY_CHANGE_SUPPORT_INFERRED_LEVEL': 'Αλλαγή Αντικειμενικού Επιπέδου Υποστήριξης',
    'PROPERTY_CHANGE_SUPPORT_LEVEL': 'Αλλαγή Επιπέδου Υποστήριξης',
    'PROPERTY_CHANGE_SUPPORT_STATUS': 'Αλλαγή Κατάστασης Υποστήριξης',
    'PROPERTY_CHANGE_TAXONOMY_LABELS': 'Αλλαγή Ετικετών',
    'PROPERTY_CHANGE_TAXONOMY_PRIORITY': 'Αλλαγή Προτεραιότητας',
    'PROPERTY_CHANGE_VOLUNTEERING_STATUS': 'Αλλαγή Κατάστασης Εθελοντισμού',
    'Query Builder': 'Δημιουργός Ερωτημάτων Αναζήτησης',
    'quotas_reach_limit_1': 'Έχετε φτάσει το όριο {limit} {itemWord} που είναι διαθέσιμο για το λογαριασμό σας',
    'Range': 'Εύρος',
    'read_only_definition_and_predefined_property_warning_1': 'Αυτή η ιδιότητα παρέχεται από το Cloutlayer και δε μπορεί να τροποποιηθεί.',
    'Reasons': 'Λόγοι',
    'Receive Call Campaign': 'Λαμβάνει Εκστρατείες Κλήσης',
    'Receive Email Campaign': 'Λαμβάνει Εκστρατείες Email',
    'Receive One To One Call': 'Λαμβάνει Προσωπικές Κλήσεις',
    'Receive One To One Email': 'Λαμβάνει Προσωπικά Email',
    'Receive One To One SMS': 'Λαμβάνει Προσωπικά SMS',
    'Receive SMS Campaign': 'Λαμβάνει Εκστρατείες SMS',
    'Region': 'Περιοχή',
    'Relationship': 'Σχέση',
    'Remembrance': 'Ενθύμηση και Ημέρα Μνήμης',
    'Renovation': 'Ανακαίνιση',
    'Repeat': 'Επανάληψη',
    'Repeat X': 'Οριζόντια Επανάληψη',
    'Repeat Y': 'Κάθετη Επανάληψη',
    'Reply To Email': 'Απάντηση σε - Email',
    'Reply To Name': 'Απάντηση σε - Όνομα',
    'Retirement': 'Σύνταξη',
    'Right': 'Δεξιά',
    'Save and continue to Email Campaign': 'Αποθήκευση και συνέχιση στην Εκστρατεία Email',
    'School, University, College, Institution, etc.': 'Σχολείο, Πανεπιστήμιο, Σχολή, Κολλέγιο, Ίδρυμα, κτλ.',
    'Search or add new label': 'Αναζητήστε ή προσθέστε νέα ετικέτα',
    'Second Color Accent': 'Χρώμα Έμφασης 2',
    'Section': 'Περιοχή',
    'Section Image': 'Εικόνα Περιοχής',
    'Select a category': 'Επιλέξτε μία κατηγορία',
    'Select all': 'Επιλογή όλων',
    'Select matched': 'Επιλογή αποτελεσμάτων',
    'Send Confirmation Email': 'Αποστολή μηνύματος επιβεβαίωσης',
    'Sender Email Address': 'Διεύθυνση Email Αποστολέα',
    'Sender Email Address deleted': 'Η Διεύθυνση Email Αποστολέα διεγράφη',
    'sender_email_address_view_subtitle': 'Επαληθεύστε τις διευθύνσεις email σας και ξεκινήστε να στέλνετε εκστρατείες',
    'September': 'Σεπτέμβριος',
    'Service Credits': 'Μονάδες',
    'Service Limitations': 'Περιορισμοί',
    'Service Quotas': 'Όρια και Χρήση',
    'Settings': 'Ρυθμίσεις',
    'Settings updated': 'Οι αλλαγές αποθηκεύτηκαν',
    'settings_branding': 'Ταυτότητα',
    'settings_credits': 'Μονάδες',
    'settings_data_privacy_and_consent': 'Ιδιωτικότητα Δεδομένων και Συγκατάθεση',
    'settings_quotas': 'Όρια και Χρήση',
    'settings_tenant_account_update_information': 'Λογαριασμός',
    'Size': 'Μέγεθος',
    'SMS Campaign': 'Εκστρατεία SMS',
    'SMS Campaign created': 'Η Εκστρατεία SMS δημιουργήθηκε',
    'SMS Campaign deleted': 'Η Εκστρατεία SMS διεγράφη',
    'SMS Campaigns': 'Εκστρατείες SMS',
    'SMS Name': 'Όνομα SMS',
    'sms_campaign_creator_notice_1': 'Αυτή η ενέργεια θα δημιουργήσει μία πρόχειρη Εκστρατεία SMS.',
    'sms_campaign_creator_notice_2': 'Κανένα μήνυμα δε θα αποσταλεί.',
    'sms_campaign_creator_notice_3': 'Το όνομα και η περιγραφή είναι περιγραφικά πεδία και δε μπορούν να τροποποιηθούν.',
    'sms_campaign_desktop_edit_1': 'Μπορείτε να επεξεργαστείτε την Εκστρατεία SMS από τον υπολογιστή σας.',
    'SMS_CAMPAIGN_EVENT': 'Εκστατεία SMS',
    'Social': 'Κοινωνικά Δίκτυα',
    'sorted by': 'ταξινομημένα κατά',
    'Source Description': 'Περιγραφή Πηγής',
    'Source Type': 'Τύπος Πηγής',
    'Spacing': 'Περιθώρια',
    'start_by_adding_blocks_v1': 'Ξεκινήστε προσθέτοντας<br>μπλοκ από το αριστερό πάνελ.',
    'START_WITH': 'αρχίζει με',
    'Status': 'Κατάσταση',
    'sub_portal_basic_subtitle': 'Ιδανικό για να το μοιραστείτε ή για να το συμπεριλάβετε στην ιστοσελίδα σας ή σε κάποια δημοσίευση',
    'sub_portal_basic_title': 'Βασικό',
    'sub_portal_one_click_extra_help_par_1': 'Οι σύνδεσμοι αυτοί πρέπει να φέρουν ως παραμέτρους το αναγνωριστικό του email/αριθμού τηλεφώνουν και τον τύπο Συνδρομής Επικοινωνίας.',
    'sub_portal_one_click_extra_help_par_2': 'Προεραιτικά, μπορούν να φέρουν το αναγνωριστικό (ID) της εκστρατείας και την προτιμώμενη γλώσσα (αν η αντίστοιχη προσθήκη είναι ενεργή).',
    'sub_portal_one_click_extra_help_real_example': 'Αυτό το παράδειγμα είναι έγκυρο και μπορεί να χρησιμοποιηθεί ως έχει σε μία Εκστρατεία Email.',
    'sub_portal_one_click_subtitle': 'Ταιριάζει σε στοχευμένες εκστρατείες ειδικά όταν χρειάζεστε να δώσετε τη δυνατότητα στους ανθρώπους σας να διαχειρίζονται τις συνδρομές επικοινωνίας τους',
    'sub_portal_one_click_title': 'Με ένα κλικ',
    'sub_portal_sub_email_title': 'Εγγραφή Email',
    'sub_portal_sub_phone_number_title': 'Εγγραφή Αριθμού Τηλεφώνου',
    'sub_portal_unsub_email_title': 'Απεγγραφή Email',
    'sub_portal_unsub_phone_number_title': 'Απεγγραφή Αριθμού Τηλεφώνου',
    'Subscriptions Portal': 'Πύλη Διαχείρισης Συνδρομών',
    'subscriptions_portal_subtitle': 'Ένα πόρταλ που βοηθά τους ανθρώπους σας να διαχειρίζονται τις συνδρομές επικοινωνίας τους με απλό τρόπο',
    'Support': 'Υποστήριξη',
    'Switch editor': 'Αλλαγή τύπου επεξεργασίας',
    'Switch to email builder?': 'Αλλαγή σε χτίστη email;',
    'Switch to HTML code editor?': 'Αλλαγή σε επεξεργαστή κώδικα HTML;',
    'TASK': 'Εργασία',
    'Taxonomy': 'Ταξινόμηση',
    'Text': 'Κείμενο',
    'Text Color': 'Χρώμα Κειμένου',
    'Text Line Height': 'Ύψος Γραμμής Κειμένου',
    'Text Size': 'Μέγεθος Κειμένου',
    'Text Style': 'Στυλ Κειμένου',
    'Text Weight': 'Βάρος Κειμένου',
    'The requested Identity Profile does not exist.': 'Το αιτούμενο Identity Profile δεν υπάρχει',
    'Timeline': 'Χρονολόγιο',
    'Timestamp': 'Ημερομηνία',
    'Title': 'Τίτλος',
    'toast_email_campaign_in_progress': 'Η Εκστρατεία Email είναι τώρα σε εξέλιξη!',
    'toast_sender_email_address_created_1': 'Ένα μήνυμα έχει αποσταλεί σε αυτή τη διεύθυνση. Παρακαλούμε ακολουθήστε τις οδηγίες που υπάρχουν στο μήνυμα',
    'toast_sms_campaign_in_progress': 'Η Εκστρατεία SMS είναι τώρα σε εξέλιξη!',
    'Tools': 'Εργαλεία',
    'tools_domain_definition_person': 'Ιδιότητες Ατόμου',
    'tools_email_templates': 'Πρότυπα Email',
    'tools_export': 'Εξαγωγή Δεδομένων',
    'tools_gdpr_add_on': 'Προσθήκη GDPR',
    'tools_i18n_add_on': 'Προσθήκη i18n',
    'tools_import': 'Εισαγωγή Δεδομένων',
    'tools_politics_add_on': 'Προσθήκη Politics',
    'tools_sender_email_addresses': 'Διευθύνσεις Email Αποστολέων',
    'tools_subscriptions_portal': 'Πύλη Διαχείρισης Συνδρομών',
    'Top': 'Πάνω',
    'Top & Bottom': 'Πάνω & Κάτω',
    'Top & Bottom / Left & Right': 'Πάνω & Κάτω / Αριστερά & Δεξιά',
    'Top Bottom': 'Πάνω Κάτω',
    'Top Bottom Left Right': 'Πάνω Κάτω Αριστερά Δεξιά',
    'Translated Label': 'Μεταφρασμένο Όνομα',
    'Travel': 'Ταξίδια',
    'Type': 'Τύπος',
    'Type to search': 'Πληκτρολογήστε για αναζήτηση…',
    'UNDER_13_YEARS_OLD': 'Κάτω των 13 ετών',
    'Unknown': 'Άγνωστο',
    'Unlimited': 'Απεριόριστο',
    'Unselect all': 'Αφαίρεση όλων',
    'Unselect matched': 'Αφαίρεση αποτελεσμάτων',
    'Upload': 'Μεταφόρτωση',
    'Usage': 'Χρήση',
    'User': 'Χρήστης',
    'Visit our Knowledge Base': 'Επισκεφθείτε τη Γνωσιακή Βάση μας',
    'Website URL': 'Ιστοσελίδα (URL)',
    'Weight': 'Βάρος',
    'Wellness & Health': 'Ευεξία και Υγεία',
    'Width': 'Μήκος',
    'Work': 'Εργασία',
    'Workplace': 'Χώρος Εργασίας',
    'Workspace': 'Χώρος Εργασίας',
    'Workspace created': 'Ο Χώρος Εργασίας δημιουργήθηκε',
    'Workspace deleted': 'Ο Χώρος Εργασίας διεγράφη',
    'Workspace must have a name': 'Ο Χώρος Εργασίας πρέπει να έχει ένα όνομα',
    'Workspace name is too long': 'Το όνομα του Χώρου Εργασίας είναι πολύ μεγάλο',
    'Workspace updated': 'Ο Χώρος Εργασίας αποθηκεύτηκε',
    'ws_type_gce_false': 'Βασικός',
    'ws_type_gce_label': 'Τύπος Αναζήτησης',
    'ws_type_gce_true': 'Σύνθετος',
    'ws_view_COMPACT': 'Συμπαγής',
    'ws_view_DEFAULT': 'Προκαθορισμένη',
    'ws_view_label': 'Πυκνότητα',
    'y.o.': 'ετών',
    'Yes': 'Ναι',
    'yes': 'yes',
    'ZIP Code': 'Ταχυδρομικός Κώδικας'
};

export default el;
